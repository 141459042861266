<template>
  <div :style="{ 'font-size': fontSize }">
    <VRow class="py-0" dense justify="center">
      <VCol>
        Level {{ level }}
        <template v-if="!milestone">
          {{ xpPresentationText }}
          <VTooltip location="top">
            <template #activator="{ props }"><span v-bind="props">
                <span class="ml-1">({{ xpUntilNextLevel }}xp)</span>
              </span></template>
            XP Until next level
          </VTooltip>
        </template>
      </VCol>
    </VRow>
    <VRow v-if="!milestone" class="py-0 my-0" justify="center">
      <VCol class="py-0 my-0" md="6">
        <VProgressLinear :value="xpPercentage" top></VProgressLinear>
      </VCol>
    </VRow>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { usePartyStore } from '../store/party'

export default defineComponent({
  name: "XPComponent",
  props: {
    showLevel: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: String,
      default: '1.25rem',
      validator: function (value: string): boolean {
        const pattern = /^(\d+(\.\d+)?rem)$|^(\d+px)$|^(\d{1,2}%)$|^(100%)$/
        return pattern.test(value)
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    xpPresentationText(): string {
      return this.xp + " / " + this.nextLevelXp
    },
    xpPercentage(): number {
      return usePartyStore().xpPercentage
    },
    xpUntilNextLevel(): number {
      return usePartyStore().xpUntilNextLevel
    },
    xp(): number {
      return usePartyStore().xp
    },
    level(): number {
      return usePartyStore().level
    },
    milestone(): boolean {
      return usePartyStore().settings?.levelSpeed === "MILESTONE"
    },
    currentLevelXp(): number {
      return usePartyStore().currentLevelXp
    },
    nextLevelXp(): number {
      return usePartyStore().nextLevelXp
    }
  }
})
</script>
