<template>
  <VBottomNavigation flat class="pt-4" min-width="650px">
    <VRow>
      <VCol>
        <VRow justify="center" class="text-center">
          <VCol cols="auto">
            <ValueComponent variant="VALUE" font-size="1rem"></ValueComponent>
          </VCol>
          <VCol cols="auto">
            <VDivider vertical />
          </VCol>
          <VCol cols="auto">
            <XPComponent font-size="1rem" />
          </VCol>
          <VCol cols="auto">
            <VDivider vertical />
          </VCol>
          <VCol v-if="useWeight" cols="auto">
            <InventoryWeightDialog :weight="totalWeight" :capacity="carryingCapacity">
            </InventoryWeightDialog>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VBottomNavigation>
</template>

<script lang="ts" setup>
import ValueComponent from "./ValueComponent.vue";
import XPComponent from "./XPComponent.vue";
import { computed } from 'vue'
import { usePartyStore } from "../store/party";
import InventoryWeightDialog from "./InventoryWeightDialog.vue";

const useWeight = computed(() => {
  return usePartyStore().settings!.useWeight
})
const totalWeight = computed(() => {
  return usePartyStore().inventoryWeight + usePartyStore().totalCarriedWeight
})
const carryingCapacity = computed(() => {
  return usePartyStore().totalCarryingCapacity
})
</script>
