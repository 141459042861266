<template>
  <div :style="{ 'font-size': fontSize }">
    <template v-if="(variant === values.all || variant.includes(values.coinage)) && coinValue">
      <VRow v-if="multiLine" dense class="py-0">
        <VCol class="py-0">
          Coinage: {{ coinValue }}
        </VCol>
      </VRow>
      <template v-else>
        Coinage: {{ coinValue }}
      </template>
    </template>
    <template v-if="(variant === values.all || variant.includes(values.valuables)) && inventoryValue">
      <VRow v-if="multiLine" dense class="py-0">
        <VCol class="py-0">
          <VExpansionPanels flat accordion tile>
            <VExpansionPanel>
              <VExpansionPanelTitle class="py-0 text-center">
                <VRow>
                  <VCol>
                    Inventory value: {{ inventoryValue }}
                  </VCol>
                </VRow>
              </VExpansionPanelTitle>
              <VExpansionPanelText class="py-0">
                <VList variant="flat" dense class="py-0 text-start">
                  <VListItem v-for="(inv, i) in inventoryValues" :key="i" class="py-0">
                    <VListItemTitle>{{ inv.name }}</VListItemTitle>
                    <VListItemSubtitle>{{ formatValue(inv.value) }}</VListItemSubtitle>
                  </VListItem>
                </VList>
              </VExpansionPanelText>
            </VExpansionPanel>
          </VExpansionPanels>
        </VCol>
      </VRow>
      <template v-else>
        Valuables: {{ inventoryValue }}
      </template>
    </template>
    <template v-if="(variant === values.all || variant.includes(values.total))">
      <VRow v-if="multiLine" dense class="py-0">
        <VCol class="py-0">
          Total: {{ totalValue }}
        </VCol>
      </VRow>
      <template v-else>
        Total: {{ totalValue }}
      </template>
    </template>
    <template v-if="variant === values.value">
      {{ totalValue }}
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ValueCombination } from '@prisma/client';
import { computed } from 'vue'
import { formatCoinValue, zeroIfNan } from "~/lib/utils";
import { usePartyStore } from '../store/party';
import { format } from 'currency-formatter';
withDefaults(defineProps<{
  variant: ValueCombination
  multiLine?: boolean
  fontSize: string
}>(), {
  variant: "ALL",
  multiLine: true,
  fontSize: '1.25rem',
})

const values = {
  all: 'ALL',
  coinage: 'COINAGE',
  valuables: 'VALUABLES',
  total: 'TOTAL',
  coinageTotal: 'COINAGE_TOTAL',
  valuablesTotal: 'VALUABLES_TOTAL',
  value: 'VALUE'
}
const totalValue = computed(() => {
  const value = '' + (usePartyStore().inventoryValue + usePartyStore().coinValue)
  return formatValue(value)
})
const inventoryValue = computed(() => {
  return formatValue(usePartyStore().inventoryValue)
})
const coinValue = computed(() => {
  const value = '' + usePartyStore().coinValue
  return formatValue(value).trim()
})
const inventoryValues = computed(() => {
  return usePartyStore().inventoryIndividualValues
})

function formatValue(value: string | number): string {
  const showAsGp = usePartyStore().settings?.showTotalValuesAsGp
  if (showAsGp) {
    return format(zeroIfNan(value, true), {
      symbol: 'gp',
      precision: 2,
      format: '%v %s'
    })
  }
  return formatCoinValue(value)
}
</script>
