<template>
  <VMenu>
    <template #activator="{ props, isActive }">
      <span v-bind="props" style="cursor: pointer;">
        Weight: {{ weightPresentation }} lbs
        <template v-if="isActive">
          <VIcon size="large">mdi-menu-down</VIcon>
        </template>
        <template v-else>
          <VIcon size="large">mdi-menu-up</VIcon>
        </template>
      </span>
    </template>
    <VCard color="grey-darken-3">
      <VCardText>
        <VTable density="compact" class="bg-transparent">
          <tbody>
            <tr v-for="inventory in inventoryWeights" :key="inventory.name + inventory.weight">
              <td>
                {{ inventory.name }}:
              </td>
              <td class="text-right">
                {{ inventory.weight }} <template v-if="inventory.maxWeight > 0">/ {{ inventory.maxWeight }}</template> lb
              </td>
            </tr>
            <tr v-if="$party.settings?.coinWeight">
              <td>
                Coins
              </td>
              <td class="text-right">
                {{ coinWeight }} lb
              </td>
            </tr>
            <tr>
              <td>
                Characters:
              </td>
              <td class="text-right">
                {{ characterWeights }} lb
              </td>
            </tr>
          </tbody>
        </VTable>
      </VCardText>
    </VCard>
  </VMenu>
</template>

<script lang="ts" setup>
import {
  computed
} from 'vue'
import { usePartyStore } from '../store/party'

const $props = withDefaults(defineProps<{
  width?: number
  weight: number
  capacity: number
  dark?: boolean
}>(), {
  width: 300,
  dark: true
})

const $party = usePartyStore()

const characterWeights = computed(() => {
  return $party.characters.reduce((acc, character) => {
    return acc + character.carriedWeight
  }, 0)
})
const coinWeight = computed(() => {
  return ($party.coinage!.platinum +
    $party.coinage!.gold +
    $party.coinage!.silver +
    $party.coinage!.copper +
    ($party.settings!.showElectrum ? $party.coinage!.electrum : 0)) /
    50
})
const weightPresentation = computed(() => {
  return $props.weight + " / " + $props.capacity
})
const inventoryWeights = computed<{ name: string, weight: number, maxWeight: number }[]>(() => $party.inventoryIndividualWeights)
</script>
