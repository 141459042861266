<template>
  <VApp class="theme--dark">
    <VAppBar app dark>
      <div class="d-flex align-center mr-5 ml-2">

        <VAppBarTitle class="font-weight-bold" style="font-size: 30px; text-decoration: none; color: white">
          Alboneon
        </VAppBarTitle>

      </div>

      <VDialog v-model="showAdblockNotice" max-width="700">
        <VCard>
          <VToolbar color="cyan">
            <VToolbarTitle>Adblock detected</VToolbarTitle>
            <VBtn icon small @click="showAdblockNotice = false">
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </VToolbar>
          <VCardText>
            This site uses sentry to track errors and usage. If you want to help out, please disable your adblocker.<br />
            No personal details are saved or tracked and nothing is shared outside the scope of this project.<br />
            Adblockers might additionally block the site from working properly.
          </VCardText>
          <VCardActions>
            <VSpacer />
            <VBtn color="primary" variant="text" @click="showAdblockNotice = false">
              Remind me later
            </VBtn>
            <VBtn color="success" variant="text" @click="showAdblockNotice = true">
              Confirm
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>

      <v-toolbar-items>
        <VBtn variant="text" to="/" exact>Home</VBtn>
        <VBtn variant="text" exact :to="{ name: 'party' }">
          <template v-if="$store.id">Change Party</template>
          <template v-else>Party Manager</template>
        </VBtn>
        <VBtn v-if="$store.id" variant="text" :to="{ name: 'party-uuid', params: { uuid: $store.id } }">{{ $store.name
        }}
        </VBtn>
        <VBtn variant="text" :to="{ name: 'dice' }">Dice Roller</VBtn>
        <VBtn>Session planner</VBtn>
      </v-toolbar-items>

      <v-spacer />


      <VBtn href="https://discord.gg/eV6Tfcb9ZN" target="_blank" variant="text">
        <font-awesome-icon icon="fa-brands fa-discord" style="font-size: 22px" />
        <span class="mr-2">Community</span>
      </VBtn>

      <VBtn href="https://gitlab.pentacore.se/penta/alboneon" target="_blank" variant="text">
        <VIcon icon="mdi-gitlab" />
        <span class="mr-2">Code</span>
      </VBtn>
    </VAppBar>

    <v-main>
      <NuxtPage />
    </v-main>

    <VFooter v-if="showHotbar" fixed dark style="height: auto; flex: 0 1 auto">
      <HotBar />
    </VFooter>
  </VApp>
</template>

<script setup lang="ts">
import { usePartyStore } from "~/store/party"
import Dexie from 'dexie';
import HotBar from "@/components/HotBar.vue";
import { ref, computed } from 'vue';
import { useHead } from "nuxt/app";
import { useRoute } from "vue-router";

useHead({
  title: "Home",
  titleTemplate: '%s | Alboneon',
  htmlAttrs: {
    lang: 'sv',
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { hid: 'description', name: 'description', content: '' },
    { name: 'format-detection', content: 'telephone=no' },
  ],
  link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.svg' }],
})

const noticeDismissed = ref(false)

const route = useRoute()
const showHotbar = computed(() => route.name?.toString().startsWith("party-"))

const db = new Dexie("adblock")
db.version(1).stores({
  adblock: '++id,notified'
})

const $store = usePartyStore()
// @ts-ignore
const adblock = await db.adblock.toCollection().first()
if (adblock === undefined) {
  // @ts-ignore
  db.adblock.add({ notified: false }, 0)
}

const showAdblockNotice = computed({
  get: function (): boolean {
    return !noticeDismissed.value && (adblock && !adblock.notified)
  },
  set: function (value: boolean) {
    if (value) {
      // @ts-ignore
      db.adblock.update(adblock?.id ?? 1, { notified: true })
    }
    noticeDismissed.value = true
  }
})
</script>

<style src="@/assets/tiptap.scss" lang="scss" />
